import { useNavigate } from "react-router-dom";
import Logo from "../images/logo.png";
import PhonePe_Logo from "../images/PhonePe_horizontal.png";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <>
      <footer class="footer">
        <div class="content has-text-centered container">
          <p>
            <p style={{ textAlign: "left" }}>
              contact us: contact@bookiteazy.com
              <hr></hr>
              <p style={{ textAlign: "left", lineHeight: "1.4" }}>
                <strong>
                  <a href="">Bookiteazy Technologies</a>
                </strong>{" "}
                by Copyright © 2023 Bookiteazy CRYG Technologies LLP. All rights
                reserved. <p style={{ textAlign: "right" }}>India</p>
              </p>
            </p>
            <p style={{ textAlign: "left", color: "#4a4a4a" }}>
              <div style={{ display: "block" }}>
                <p>
                  We are using India's most trusted Payment Gateway. 100% SECURE
                </p>{" "}
                <img src={PhonePe_Logo} style={{ width: "7em" }} />
              </div>
              <a
                style={{ color: "#4a4a4a" }}
                onClick={() => navigate("/hcp/privacy-policy")}
              >
                <u>Privacy Policy</u>
                <img
                  src={Logo}
                  className="has-text-centered"
                  style={{
                    margin: "0 auto",
                    display: "block",
                    width: "25em",
                  }}
                />
              </a>
              <a
                style={{
                  fontSize: "0.8em",
                  color: "#4a4a4a",
                  margin: "0 0.5em",
                }}
                onClick={() => navigate("/TermsAndConditions")}
              >
                Terms and Conditions
              </a>
              <a
                style={{
                  fontSize: "0.8em",
                  color: "#4a4a4a",
                  margin: "0 0.5em",
                }}
                onClick={() => navigate("/CancellationAndRefundPolicy")}
              >
                Cancellation/Refund Policy
              </a>
              <a
                style={{
                  fontSize: "0.8em",
                  color: "#4a4a4a",
                  margin: "0 0.5em",
                }}
                onClick={() => navigate("/PricingPolicy")}
              >
                Pricing Policy
              </a>
              <a
                style={{
                  fontSize: "0.8em",
                  color: "#4a4a4a",
                  margin: "0 0.5em",
                }}
                onClick={() => navigate("/ShippingPolicy")}
              >
                Shipping Policy
              </a>
              <a
                style={{
                  fontSize: "0.8em",
                  color: "#4a4a4a",
                  margin: "0 0.5em",
                }}
                onClick={() => navigate("/ContactUs")}
              >
                Contact Us
              </a>
              {/* <|  <a  style={{ color:"#4a4a4a" }} href="https://linkedin.com/company/bookiteazy-technologies">
                    Other Policies
                  </a>> */}
            </p>
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
