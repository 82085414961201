import Logo from "../components/landingUI/logo.png";
import { useEffect, useState } from "react";
import "../App.css";
import { useNavigate } from "react-router-dom";

const TC = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex flex-col">
        {/* header begin */}
        <div className="flex justify-center">
        <a href="/">
            <img
              src={Logo}
              alt="MainLogo"
              className="size-56 shadow-2xl shadow-orange-600 rounded-b-2xl"
            />
          </a>
        </div>
        {/* header end */}

        <div class="mx-10 mt-20 mb-10">
          <h1 className="text-center text-orange-100 text-2xl font-semibold">
            Terms and Conditions for "Bookiteazy CRYG Technologies LLP"
          </h1>
        </div>

        {/* main begin */}
        <div className="mx-10 text-orange-50">
          <p style={{ textAlign: "justify" }}>
            Effective Date: 03-10-2024
            <br></br>
            <br></br>
            Thank you for your interest in Bookiteazy CRYG Technologies LLP.
            This Terms and Conditions outlines for terms, conditions, terms of
            use of App.
          </p>
          <br></br>
          <p>
            These Terms of Use govern your access to and use of our app. By
            using our app, you agree to these terms.
          </p>
          <br />
          <ul
            style={{
              textAlign: "left",
            }}
          >
            <br />
            <li>
              <b>User Responsibilities: </b> You agree to use our app only for
              lawful purposes and in accordance with these terms. You are
              responsible for maintaining the confidentiality of your account
              information and for all activities that occur under your account.
            </li>
            <br />
            <li>
              <b>Intellectual Property: </b> All content and materials available
              through the app, including trademarks and logos, are the property
              of Bookiteazy CRYG Technologies LLP and protected by intellectual
              property laws.
            </li>
            <br />
            <li>
              <b>Limitation of Liability: </b> Bookiteazy CRYG Technologies LLP
              is not liable for any indirect, incidental, or consequential
              damages arising from your use of the app. Our liability is limited
              to the maximum extent permitted by law.
            </li>
            <br />
            <li>
              <b>Termination: </b>We reserve the right to suspend or terminate
              your access to the app if you violate these terms or for any other
              reason at our discretion.
            </li>
            <br />
            <li>
              <b>Contact Us: </b>If you have any questions or need assistance,
              please reach out to our customer service team at
              contact@bookiteazy.com.
            </li>
            <br />
            <li>
              <b>Disclaimer of Warranties: </b>We do not provide any warranties,
              expressed or implied, regarding the functionality or performance
              of our app. Users acknowledge that they use the app at their own
              risk and that we are not responsible for any issues that may arise
              from its use.
            </li>
            <br />
            <li>
              <b>Rules of Conduct: </b>Users are expected to interact with the
              app respectfully and responsibly. Any abusive or harmful behavior
              may result in the suspension or termination of your account.
              Additionally, if we find that you have created an account but are
              not using our services for a reasonable period of time, we reserve
              the right to terminate your account without prior notice.
            </li>
            <br />
            <li>
              <b>User Restrictions: </b>
              Users are prohibited from:
              <ul>
                <li>
                  1. Using the app for any illegal or unauthorized purpose.
                </li>
                <li>
                  2. Attempting to gain unauthorized access to any part of the
                  app or its related systems.
                </li>
                <li>3. Sharing their account credentials with others.</li>
              </ul>
            </li>
          </ul>
        </div>
        {/* main end */}

        <footer className="mt-20 mb-5 bg-orange-400 text-white">
          <div className="content has-text-centered container">
            <p className="mt-2">
              <a
                className="text-white text-sm mx-2"
                onClick={() => navigate("/PrivacyPolicy")}
              >
                Privacy Policy
              </a>
              <span style={{ margin: "0 0.2em" }}></span>
              <a
                className="text-white text-sm mx-2"
                onClick={() => navigate("/TermsAndConditions")}
              >
                Terms and Conditions
              </a>
              <a
                className="text-white text-sm mx-2"
                onClick={() => navigate("/CancellationAndRefundPolicy")}
              >
                Cancellation/Refund Policy
              </a>
              <a
                className="text-white text-sm mx-2"
                onClick={() => navigate("/PricingPolicy")}
              >
                Pricing Policy
              </a>
              <a
                className="text-white text-sm mx-2"
                onClick={() => navigate("/ShippingPolicy")}
              >
                Shipping Policy
              </a>
              <a
                className="text-white text-sm mx-2"
                onClick={() => navigate("/ContactUs")}
              >
                Contact Us
              </a>
            </p>
            <p className="mt-2">
              © Copyright 2024 Bookiteazy CRYG Technologies LLP. <br />All rights reserved.{" "}{" "}
            </p>
            {/* <p className="text-right">India</p> */}
          </div>
        </footer>
      </div>
    </>
  );
};

export default TC;
