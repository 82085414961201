// src/components/App.js
import React, { useState } from "react";
// import "./../components/App.css";
import "../../src/styling.css";
import header_banner from "../images/bookiteazy_header_image_transparent.png";
import Logo from "../images/logo.png";
import Your_work from "../images/bookiteazy_your_work.png";
import Our_Automation from "../images/bookiteazy_automation.png";
import More_features from "../images/bookiteazy_more_features.png";
import BOOKITEAZY_STEP1 from "../images/BOOKITEAZY_STEP1.png";
import BOOKITEAZY_STEP2 from "../images/BOOKITEAZY_STEP2.png";
import BOOKITEAZY_STEP3 from "../images/BOOKITEAZY_STEP3.png";
import BOOKITEAZY_STEP4 from "../images/BOOKITEAZY_STEP4.png";
import Feature1 from "../images/Feature1.png";
import Feature2 from "../images/Feature2.png";
import Feature3 from "../images/Feature3.png";
import Feature4 from "../images/Feature4.png";
import Feature5 from "../images/Feature5.png";
import Onthefly from "../images/FirstEverFeature.png";
import PhonePe_Logo from "../images/PhonePe_horizontal.png";
import Color from "../images/color.png";
import { onBoarding, paymentIniatialize } from "../API";
import { useNavigate } from "react-router-dom";
import FAQs from "./Faqs";
import OnboardHomepageSections from "./OnboardHomepageSections";
import Header from "./Header";
import Footer from "./Footer";

function OnboardClientsHCP() {
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    practice: "",
    plan: "Quarterly",
    number: "",
    professional: "",
  });
  const [errorFields, setErrorFields] = useState([]);
  const [redirected, setredirected] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const emailValidator = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  function checkErrors() {
    const errors = [];

    const isEmailValid = emailValidator(formData?.email);

    if (!isEmailValid) errors.push("Invalid Email");
    if (!formData.fullName) errors.push("Full Name");
    if (!formData.professional) errors.push("Profession");
    if (!formData.email) errors.push("Email");
    if (!formData.number) errors.push("Number");
    if (formData.number && formData.number.length !== 10)
      errors.push("Only 10 digits Contact Number allowed!");
    if (!formData.practice) errors.push("Practice Name");

    setErrorFields(errors);
    return errorFields;
  }

  const handleSubmit = () => {
    setButtonDisabled(true);
    const payload = {
      fullName: formData.fullName,
      email: formData.email,
      professional: formData.professional,
      practiceName: formData.practice,
      plan: formData.plan,
      number: formData.number,
    };
    // Check all steps for incomplete fields
    const err = checkErrors();

    if (
      formData.fullName &&
      formData.email &&
      formData.number &&
      formData.plan &&
      formData.practice &&
      formData.professional &&
      formData.number.length === 10
    ) {
      onBoarding(payload)
        .then(async (onBoardingRespone) => {
          if (
            onBoardingRespone.error ===
            "Account already exist with email or number. Try Login!"
          ) {
            setredirected(false);
            alert("Account already exist with email or number. Try Login!");
          } else {
            const reqBody = {
              plan: formData.plan,
              fullName: formData.fullName,
              client_id: formData?.number,
            };
            setredirected(true);
            const res = await paymentIniatialize(reqBody);
            window.location.href = res.data;
          }
        })
        // .then((paymentResponse) => {
        //   console.log({ paymentResponse });
        //   setPaymentStatus(JSON.stringify(paymentResponse));
        // })
        .catch((err) => {
          console.log("Error in Submit API", err);
        });
    }
    setFormData({
      fullName: "",
      email: "",
      practice: "",
      plan: "Quarterly",
      number: "",
      professional: "",
    });
  };

  const scrollTo = (id) => {
    let destinationDiv = "";
    // Find the destination div by its id
    if (id === "apply") {
      destinationDiv = document.getElementById("started");
    } else if (id === "how") {
      destinationDiv = document.getElementById("howSection");
    } else if (id === "faqs") {
      destinationDiv = document.getElementById("faqs");
    }

    // Scroll to the destination div smoothly
    if (destinationDiv) {
      destinationDiv.scrollIntoView({ behavior: "smooth" });
    }
  };

  const checkDisabled = () => {
    if (
      formData.fullName === "" ||
      formData.email === "" ||
      formData.practice === "" ||
      formData.number === "" ||
      formData.professional === "" ||
      errorFields.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Header />
      <div class="container">
        <OnboardHomepageSections />
        <section id="startedSection" className="mb-6">
          <h1 class="title is-4rem mb-0 text-centered has-text-centered mb-6">
            Let's Get Started
          </h1>
          <h2
            class="subtitle is-1 has-text-centered mb-6"
            style={{ marginBottom: "2rem" }}
          >
            See your practice grow with the help of Bookiteazy.<a>*</a>
          </h2>

          {redirected ? (
            <>
              <div
                class="tile"
                id="started"
                style={{
                  display: "block",
                  backgroundColor: "#f5f5f5",
                  padding: "5em",
                }}
              >
                <div
                  class="control is-loading-re"
                  style={{ fontSize: "1rem", textAlign: "center" }}
                >
                  Processing
                  <br></br>
                  You will be redirected to the payment gateway for making
                  payment. It might take few seconds.
                  <br></br>
                  <strong>
                    Do not refresh page or click "Back" or "Close" on browser.
                  </strong>
                  {/* You're redirected to secured payment gateway */}
                </div>
              </div>
            </>
          ) : (
            <>
              <div class="tile" id="started">
                <div class="tile is-parent is-vertical">
                  <article
                    class="tile is-child notification"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      background: "#f5f5f5",
                    }}
                  >
                    <div class="field is-grouped is-grouped-multiline is-grouped-centered">
                      <div class="field mr-2">
                        <label class="label">Full Name</label>
                        <div class="control">
                          <input
                            onChange={handleInputChange}
                            class="input"
                            name="fullName"
                            type="text"
                            placeholder="Chintan Raval"
                            value={formData.fullName}
                          />
                        </div>
                      </div>
                      <div class="field mr-2 ">
                        <label class="label">Profession</label>
                        <div class="select is-normal" style={{ width: "13em" }}>
                          <select
                            name="professional"
                            style={{ width: "13em" }}
                            onChange={handleInputChange}
                            value={formData.professional}
                          >
                            <option value={""} disabled selected>
                              --Select--
                            </option>
                            <option value={"Doctor"}>Doctor</option>
                            <option value={"Dentist"}>Dentist</option>
                            <option value={"Therapist"}>Therapist</option>
                          </select>
                        </div>
                      </div>{" "}
                      <div class="field mr-2 ">
                        <label class="label">Email</label>
                        <div class="control">
                          <input
                            onChange={handleInputChange}
                            class="input"
                            type="email"
                            name="email"
                            placeholder="Email id"
                            value={formData.email}
                          />
                        </div>
                      </div>
                      <div class="field mr-2 ">
                        <label class="label">Number</label>
                        <div class="control">
                          <input
                            onChange={handleInputChange}
                            class="input"
                            type="text"
                            name="number"
                            placeholder="1234567890"
                            value={formData.number}
                          />
                        </div>
                      </div>
                      <div class="field">
                        <label class="label">Practice Name</label>
                        <div class="control">
                          <input
                            onChange={handleInputChange}
                            class="input"
                            type="email"
                            name="practice"
                            placeholder="Practice name"
                            value={formData.practice}
                          />
                        </div>
                      </div>
                      <div
                        class="field control has-text-centered"
                        style={{
                          inlineSize: "fit-content",
                        }}
                      >
                        <label class="label">
                          Which Plan works for you?<br></br>
                          <small>(excludes convenience charges 2%)</small>
                        </label>
                        <label class="radio mr-2 plans">
                          <input
                            onChange={handleInputChange}
                            type="radio"
                            className="mr-1"
                            name="plan"
                            value={"Quarterly"}
                            checked
                          />
                          <b>Quaterly</b>
                          <br></br>
                          <i>
                            <s>Rs. 4500</s>
                            <br></br>
                            Get 20%
                          </i>
                          <br></br>
                          Rs. 3600
                        </label>
                        <label class="radio mr-2  plans">
                          <input
                            onChange={handleInputChange}
                            type="radio"
                            className="mr-1"
                            value={"SemiAnnual"}
                            name="plan"
                          />
                          <b>Semi Annual</b>
                          <br></br>{" "}
                          <i>
                            <s>Rs. 9000</s>
                            <br></br>
                            Get 25%
                          </i>
                          <br></br>
                          Rs. 6750
                        </label>
                        <label class="radio plans mb-4">
                          <input
                            onChange={handleInputChange}
                            type="radio"
                            className="mr-1"
                            value={"Annual"}
                            name="plan"
                          />
                          <b>Annual</b>
                          <br></br>
                          <i>
                            <s>Rs. 18000</s>
                            <br></br>
                            Get 30%
                          </i>
                          <br></br>
                          Rs. 12600
                        </label>
                        {errorFields.length > 0 && (
                          <p
                            style={{
                              width: "23em",
                              color: "red",
                              fontWeight: "400",
                            }}
                            className="mb-2"
                          >
                            # Please fill out fields:
                            <b> {errorFields.join(", ")}</b>
                          </p>
                        )}
                        <p>
                          By clicking 'Submit,' you acknowledge and accept the
                          terms outlined in the{" "}
                          <a onClick={() => scrollTo("faqs")} href>
                            FAQs
                          </a>
                          ,<br></br> including the disclaimer about potential
                          changes to the official launch date.<br></br> Stay
                          informed and connected for any updates.
                        </p>
                        <p class="control">
                          <a
                            class="button is-primary"
                            disabled={() => checkDisabled()}
                            onClick={() => handleSubmit()}
                          >
                            {"Submit"}
                          </a>
                        </p>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </>
          )}
        </section>
        <div id="faqs">
          <FAQs />
        </div>{" "}
      </div>
      <Footer />
    </>
  );
}

export default OnboardClientsHCP;
