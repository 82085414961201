import Logo from "../components/landingUI/logo.png";
import { useEffect, useState } from "react";
import "../App.css";
import { useNavigate } from "react-router-dom";

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex flex-col">
        {/* header begin */}
        <div className="flex justify-center">
        <a href="/">
            <img
              src={Logo}
              alt="MainLogo"
              className="size-56 shadow-2xl shadow-orange-600 rounded-b-2xl"
            />
          </a>
        </div>
        {/* header end */}

        <div class="mx-10 mt-20 mb-10">
          <h1 className="text-center text-orange-100 text-2xl font-semibold">
            Refund and Cancellation Policies for "Bookiteazy CRYG Technologies
            LLP"
          </h1>
        </div>

        {/* main begin */}
        <div className="mx-10 text-orange-50">
          <p style={{ textAlign: "justify" }}>
            Effective Date: 03-10-2024
            <br></br>
            <br></br>
            Thank you for your interest in Bookiteazy CRYG Technologies LLP.
            This Refund/Cancellation Policies outlines on our page.
          </p>
          <br></br>

          <ul
            style={{
              textAlign: "left",
            }}
          >
            <li>
              <b>Delivery Options:</b> We offer delivery services through our
              app. To ensure a smooth delivery experience, please provide
              accurate delivery information and follow the delivery
              instructions.{" "}
            </li>
            <br />
            <li>
              <b>Delivery Safety:</b> For safety and hygiene reasons, please
              keep a bag or box outside your home for the delivery person to
              place the vegetables. Bookiteazy CRYG Technologies LLP is not
              responsible for items left outside if no receptacle is provided.
            </li>
            <br />
            <li>
              <b>No Refund Policy:</b> Once an order is confirmed and payment is
              completed, we are unable to process refunds. We appreciate your
              understanding, and if you have any questions or concerns about
              your order, please feel free to reach out to us.{" "}
            </li>
            <br />
            <li>
              <b>No Replacement Policy:</b> Bookiteazy CRYG Technologies LLP
              does not offer replacements for any items once the delivery is
              made or the order is picked up.
            </li>
            <br />
            <li>
              <b>Cancellation Option:</b>You can cancel your order if you choose
              delivery or pickup, but please do so on the same day the order is
              placed, before 12:00 PM. Unfortunately, cancellations made after
              this time or for other delivery options cannot be accepted. Thank
              you for your understanding!
            </li>
            <br />
            <li>
              <b>Non-Refundable Platform Fee:</b> In case of a successful
              cancellation, the platform fee is non-refundable. The remaining
              amount, if any, will be refunded as per the applicable policies.
              Order Verification and Pickup.
            </li>
            {/*<br />
           <li>
            <b>Local Stores:</b> Bookiteazy CRYG Technologies LLP partners with real physical local
            grocery, vegetable, and fruit stores. We do not operate warehouses
            or dark stores. As such, we do not offer refunds and ask users to
            check their orders before leaving the premises, store door, or
            pickup area. Once you have left, you will not be able to raise any
            issues or request corrections.
          </li> */}
            <br />
          </ul>
          <br></br>
        </div>
        {/* main end */}

        <footer className="mt-20 mb-5 bg-orange-400 text-white">
          <div className="content has-text-centered container">
            <p className="mt-2">
              <a
                className="text-white text-sm mx-2"
                onClick={() => navigate("/PrivacyPolicy")}
              >
                Privacy Policy
              </a>
              <span style={{ margin: "0 0.2em" }}></span>
              <a
                className="text-white text-sm mx-2"
                onClick={() => navigate("/TermsAndConditions")}
              >
                Terms and Conditions
              </a>
              <a
                className="text-white text-sm mx-2"
                onClick={() => navigate("/CancellationAndRefundPolicy")}
              >
                Cancellation/Refund Policy
              </a>
              <a
                className="text-white text-sm mx-2"
                onClick={() => navigate("/PricingPolicy")}
              >
                Pricing Policy
              </a>
              <a
                className="text-white text-sm mx-2"
                onClick={() => navigate("/ShippingPolicy")}
              >
                Shipping Policy
              </a>
              <a
                className="text-white text-sm mx-2"
                onClick={() => navigate("/ContactUs")}
              >
                Contact Us
              </a>
            </p>
            <p className="mt-2">
              © Copyright 2024 Bookiteazy CRYG Technologies LLP. <br />All rights reserved.{" "}{" "}
            </p>
            {/* <p className="text-right">India</p> */}
          </div>
        </footer>
      </div>
    </>
  );
};

export default PrivacyPolicy;
