import React, { useState } from "react";
import "../../src/styling.css";
import header_banner from "../images/bookiteazy_header_image_transparent.png";
import Your_work from "../images/bookiteazy_your_work.png";
import Our_Automation from "../images/bookiteazy_automation.png";
import BOOKITEAZY_STEP1 from "../images/BOOKITEAZY_STEP1.png";
import BOOKITEAZY_STEP2 from "../images/BOOKITEAZY_STEP2.png";
import BOOKITEAZY_STEP3 from "../images/BOOKITEAZY_STEP3.png";
import BOOKITEAZY_STEP4 from "../images/BOOKITEAZY_STEP4.png";
import Feature1 from "../images/Feature1.png";
import Feature2 from "../images/Feature2.png";
import Feature3 from "../images/Feature3.png";
import Feature4 from "../images/Feature4.png";
import Feature5 from "../images/Feature5.png";
import Onthefly from "../images/FirstEverFeature.png";
import Color from "../images/color.png";

const OnboardHomepageSections = () => {
    const [isFlipped, setFlipped] = useState(false);
    const [isFlipped2, setFlipped2] = useState(false);


  const flipCard = () => {
    setFlipped(!isFlipped);
  };
  const flipCard2 = () => {
    setFlipped2(!isFlipped2);
  };
  
  return (
    <>
      <section
        id="heroSection"
        class="section is-large"
        style={{
          paddingLeft: "2rem",
          paddingRight: "2rem",
          paddingBottom: "2rem",
          paddingTop: "14rem",
        }}
      >
        <div class="custom-container">
          <img
            src={Color}
            style={{
              margin: "-38em 1em",
              width: "-webkit-fill-available",
            }}
          />
          <h1 class="title mb-1">
            India's first ever automated platform for health care professionals.
          </h1>
          <h1 class="title is-4rem mb-0">The simplicity of appointments.</h1>
          <h2 class="title is-4rem" style={{ marginBottom: "2rem" }}>
            Streamlined by Bookiteazy.
          </h2>
        </div>

        <nav
          class="level"
          style={{ display: "flex", justifyContent: "space-around" }}
        >
          <div class="level-item has-text-centered">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="60"
                width="60"
                viewBox="0 0 448 512"
              >
                <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-96 55.2C54 332.9 0 401.3 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7c0-81-54-149.4-128-171.1V362c27.6 7.1 48 32.2 48 62v40c0 8.8-7.2 16-16 16H336c-8.8 0-16-7.2-16-16s7.2-16 16-16V424c0-17.7-14.3-32-32-32s-32 14.3-32 32v24c8.8 0 16 7.2 16 16s-7.2 16-16 16H256c-8.8 0-16-7.2-16-16V424c0-29.8 20.4-54.9 48-62V304.9c-6-.6-12.1-.9-18.3-.9H178.3c-6.2 0-12.3 .3-18.3 .9v65.4c23.1 6.9 40 28.3 40 53.7c0 30.9-25.1 56-56 56s-56-25.1-56-56c0-25.4 16.9-46.8 40-53.7V311.2zM144 448a24 24 0 1 0 0-48 24 24 0 1 0 0 48z" />
              </svg>
              <p class="heading">Doctors</p>
              {/* <p class="title">3,456</p> */}
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="60"
                width="60"
                viewBox="0 0 448 512"
              >
                <path d="M186.1 52.1C169.3 39.1 148.7 32 127.5 32C74.7 32 32 74.7 32 127.5v6.2c0 15.8 3.7 31.3 10.7 45.5l23.5 47.1c4.5 8.9 7.6 18.4 9.4 28.2l36.7 205.8c2 11.2 11.6 19.4 22.9 19.8s21.4-7.4 24-18.4l28.9-121.3C192.2 323.7 207 312 224 312s31.8 11.7 35.8 28.3l28.9 121.3c2.6 11.1 12.7 18.8 24 18.4s20.9-8.6 22.9-19.8l36.7-205.8c1.8-9.8 4.9-19.3 9.4-28.2l23.5-47.1c7.1-14.1 10.7-29.7 10.7-45.5v-2.1c0-55-44.6-99.6-99.6-99.6c-24.1 0-47.4 8.8-65.6 24.6l-3.2 2.8 19.5 15.2c7 5.4 8.2 15.5 2.8 22.5s-15.5 8.2-22.5 2.8l-24.4-19-37-28.8z" />
              </svg>
              <p class="heading">Dentists</p>
              {/* <p class="title">123</p> */}
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="60"
                width="60"
                viewBox="0 0 512 512"
              >
                <path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm177.6 62.1C192.8 334.5 218.8 352 256 352s63.2-17.5 78.4-33.9c9-9.7 24.2-10.4 33.9-1.4s10.4 24.2 1.4 33.9c-22 23.8-60 49.4-113.6 49.4s-91.7-25.5-113.6-49.4c-9-9.7-8.4-24.9 1.4-33.9s24.9-8.4 33.9 1.4zM144.4 208a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm192-32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
              </svg>
              <p class="heading">Therapists</p>
              {/* <p class="title">456K</p> */}
            </div>
          </div>
        </nav>
        <img
          style={{ width: "fit-content", marginTop: "4em" }}
          src={header_banner}
        />
      </section>
      <section id="featuresSection" class="section">
        <p class="title is-1 has-text-centered mt-6">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="40"
            width="60"
            viewBox="0 0 576 512"
          >
            <path d="M384 160c-17.7 0-32-14.3-32-32s14.3-32 32-32H544c17.7 0 32 14.3 32 32V288c0 17.7-14.3 32-32 32s-32-14.3-32-32V205.3L342.6 374.6c-12.5 12.5-32.8 12.5-45.3 0L192 269.3 54.6 406.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160c12.5-12.5 32.8-12.5 45.3 0L320 306.7 466.7 160H384z" />
          </svg>{" "}
          STREAMLINE MORE PATIENTS.{" "}
        </p>
        <div class="column mb-6">
          <div class="columns is-mobile column">
            <div class="column flip-container">
              <p class="bd-notification is-info">
                <div className={`flip-container ${isFlipped ? "flip" : ""}`}>
                  <div
                    class="card flipper"
                    style={{
                      height: "15em",
                      color: "#8b9eb7",
                      borderRadius: "1rem",
                    }}
                  >
                    <div class="card-content front">
                      <p
                        class="title phoneTitle"
                        style={{ textAlign: "center", color: "#8b9eb7" }}
                      >
                        YOUR WORK.
                      </p>
                      <nav class="level">
                        <div class="level-item has-text-centered">
                          <img
                            style={{ width: "29em", margin: "-1em 0" }}
                            src={Your_work}
                          />
                        </div>
                      </nav>
                      {/* <p class="subtitle">Jeff Atwood</p> */}
                    </div>
                    <div class="card-content back">
                      <p
                        class="title phoneTitle"
                        style={{ textAlign: "center", color: "#8b9eb7" }}
                      >
                        "Your healing touch inspires trust, inviting patients to
                        return."
                      </p>
                      {/* <p class="subtitle">Jeff Atwood</p> */}
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        bottom: "0",
                        margin: "1em",
                        right: "0",
                      }}
                    >
                      <p onClick={() => flipCard()}>
                        <span>
                          {isFlipped ? (
                            <>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="20"
                                width="20"
                                viewBox="0 0 512 512"
                              >
                                <path
                                  fill="#8b9eb7"
                                  d="M48.5 224H40c-13.3 0-24-10.7-24-24V72c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2L98.6 96.6c87.6-86.5 228.7-86.2 315.8 1c87.5 87.5 87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3c-62.2-62.2-162.7-62.5-225.3-1L185 183c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8H48.5z"
                                />
                              </svg>
                            </>
                          ) : (
                            "Learn More"
                          )}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </p>
            </div>
            {/* Our automation card */}
            <div class="column flip-container mb-4">
              <p class="bd-notification is-info">
                <div className={`flip-container ${isFlipped2 ? "flip" : ""}`}>
                  <div
                    class="card flipper"
                    style={{
                      height: "15em",
                      color: "#8b9eb7",
                      borderRadius: "1rem",
                    }}
                  >
                    <div class="card-content front">
                      <p
                        class="title phoneTitle"
                        style={{ textAlign: "center", color: "#8b9eb7" }}
                      >
                        OUR AUTOMATION.
                      </p>
                      <nav class="level">
                        <div class="level-item has-text-centered">
                          <img
                            style={{ width: "10em", margin: "-1em 0" }}
                            src={Our_Automation}
                          />
                        </div>
                      </nav>
                      {/* <p class="subtitle">Jeff Atwood</p> */}
                    </div>
                    <div class="card-content back">
                      <p
                        class="title phoneTitle"
                        style={{ textAlign: "center", color: "#8b9eb7" }}
                      >
                        "Do not miss appointments, even when you are sleeping" ~
                        only on Bookiteazy
                      </p>
                      {/* <p class="subtitle">Jeff Atwood</p> */}
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        bottom: "0",
                        margin: "1em",
                        right: "0",
                      }}
                    >
                      <p onClick={() => flipCard2()}>
                        <span>
                          {isFlipped2 ? (
                            <div style={{ textAlign: "right" }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="20"
                                width="20"
                                viewBox="0 0 512 512"
                              >
                                <path
                                  fill="#8b9eb7"
                                  d="M48.5 224H40c-13.3 0-24-10.7-24-24V72c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2L98.6 96.6c87.6-86.5 228.7-86.2 315.8 1c87.5 87.5 87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3c-62.2-62.2-162.7-62.5-225.3-1L185 183c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8H48.5z"
                                />
                              </svg>
                            </div>
                          ) : (
                            "Learn More"
                          )}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        id="morefeaturesSection"
        className="mb-6"
        style={{
          border: "1px solid rgb(250, 250, 250)",
          boxShadow: "4px 12px 40px 6px rgba(0,0,0,0.09)",
          borderRadius: "2em",
        }}
      >
        <div class="custom-container howWorksPadding">
          <h1 class="title is-4rem mb-0">
            Upgrade to Efficiency.<br></br>Your Next Appointment System.
          </h1>
          <hr></hr>
          <h2 class="subtitle is-4rem mb-6" style={{ marginBottom: "2rem" }}>
            Discover the Power of Seamlessly Managed Appointments with
            Bookiteazy
          </h2>
          <div class="tile is-ancestor">
            <div class="tile is-vertical is-4">
              <div class="tile">
                <div class="tile is-parent">
                  <img src={Feature1} />
                </div>
              </div>
            </div>
            <div class="tile is-vertical is-4">
              <div class="tile">
                <div class="tile is-parent">
                  <img src={Feature2} />
                </div>
              </div>
            </div>
            <div class="tile is-vertical is-4">
              <div class="tile">
                <div class="tile is-parent">
                  <img src={Feature3} />
                </div>
              </div>
            </div>
          </div>
          <div class="tile is-ancestor">
            <div class="tile is-vertical is-6">
              <div class="tile">
                <div class="tile is-parent">
                  <img src={Feature4} />
                </div>
              </div>
            </div>
            <div class="tile is-vertical is-6">
              <div class="tile">
                <div class="tile is-parent">
                  <img src={Feature5} />
                </div>
              </div>
            </div>
          </div>
          <div class="tile is-ancestor">
            <div class="tile is-vertical">
              <div class="tile">
                <div class="tile is-parent">
                  <img
                    src={Onthefly}
                    style={{
                      margin: "-6em 0 0 0",
                      width: "-webkit-fill-available",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <img src={More_features} /> */}
      </section>
      <section
        id="howSection"
        className="mb-6"
        style={{
          border: "1px solid rgb(250, 250, 250)",
          boxShadow: "4px 12px 40px 6px rgba(0,0,0,0.09)",
          borderRadius: "2em",
        }}
      >
        <div class="custom-container howWorksPadding">
          <h1 class="title is-4rem mb-0">Get 5+ Patients per month</h1>
          <h2 class="title is-4rem" style={{ marginBottom: "2rem" }}>
            @ BOOKITEAZY.<a>*</a>
          </h2>
          <hr></hr>
          <h2 class="subtitle is-4rem mb-6" style={{ marginBottom: "2rem" }}>
            How it all works?
          </h2>
          <div class="tile is-ancestor">
            <div class="tile is-vertical is-6">
              <div class="tile">
                <div class="tile is-parent">
                  <img src={BOOKITEAZY_STEP1} />
                </div>
              </div>
            </div>
            <div class="tile is-vertical is-6">
              <div class="tile">
                <div class="tile is-parent">
                  <img src={BOOKITEAZY_STEP2} />
                </div>
              </div>
            </div>
          </div>
          <div class="tile is-ancestor">
            <div class="tile is-vertical is-6">
              <div class="tile">
                <div class="tile is-parent">
                  <img src={BOOKITEAZY_STEP3} />
                </div>
              </div>
            </div>
            <div class="tile is-vertical is-6">
              <div class="tile">
                <div class="tile is-parent">
                  <img src={BOOKITEAZY_STEP4} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OnboardHomepageSections;
