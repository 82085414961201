import Logo from "../components/landingUI/logo.png";
import { useEffect, useState } from "react";
import "../App.css";
import { useNavigate } from "react-router-dom";

const PricingPolicy = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex flex-col h-screen">
        {/* header begin */}
        <div className="flex justify-center">
        <a href="/">
            <img
              src={Logo}
              alt="MainLogo"
              className="size-56 shadow-2xl shadow-orange-600 rounded-b-2xl"
            />
          </a>
        </div>
        {/* header end */}

        <div class="mx-10 mt-20 mb-10">
          <h1 className="text-center text-orange-100 text-2xl font-semibold">
            Pricing Policy for "Bookiteazy CRYG Technologies LLP"
          </h1>
        </div>

        {/* main begin */}
        <div className="mx-10 text-orange-50">
          <p style={{ textAlign: "justify" }}>
            Effective Date: 03-10-2024
            <br></br>
            <br></br>
            Thank you for your interest in our Bookiteazy CRYG Technologies LLP!
            We are currently in the process of drafting our shipping policy.
          </p>
          <br></br>

          <ul
            style={{
              textAlign: "left",
            }}
          >
            <li>
              <b>Payment Options:</b> Our app is completely free to download
              from the App Store(iphone users) and also from Google Play
              Store(android phone users). You can enjoy our app services without
              any upfront costs.
            </li>
            <br />
          </ul>
        </div>
        {/* main end */}

        <footer className="mt-20 mb-5 bg-orange-400 text-white">
          <div className="content has-text-centered container">
            <p className="mt-2">
              <a
                className="text-white text-sm mx-2"
                onClick={() => navigate("/PrivacyPolicy")}
              >
                Privacy Policy
              </a>
              <span style={{ margin: "0 0.2em" }}></span>
              <a
                className="text-white text-sm mx-2"
                onClick={() => navigate("/TermsAndConditions")}
              >
                Terms and Conditions
              </a>
              <a
                className="text-white text-sm mx-2"
                onClick={() => navigate("/CancellationAndRefundPolicy")}
              >
                Cancellation/Refund Policy
              </a>
              <a
                className="text-white text-sm mx-2"
                onClick={() => navigate("/PricingPolicy")}
              >
                Pricing Policy
              </a>
              <a
                className="text-white text-sm mx-2"
                onClick={() => navigate("/ShippingPolicy")}
              >
                Shipping Policy
              </a>
              <a
                className="text-white text-sm mx-2"
                onClick={() => navigate("/ContactUs")}
              >
                Contact Us
              </a>
            </p>
            <p className="mt-2">
              © Copyright 2024 Bookiteazy CRYG Technologies LLP. <br />All rights reserved.{" "}{" "}
            </p>
            {/* <p className="text-right">India</p> */}
          </div>
        </footer>
      </div>
    </>
  );
};

export default PricingPolicy;
