import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  onBoarding,
  paymentIniatialize,
  paymentStatusCheck,
} from "../API/index";
import Logo from "../images/logo.png";
import BOOKITEAZY_STEP1 from "../images/BOOKITEAZY_STEP1.png";
import BOOKITEAZY_STEP2 from "../images/BOOKITEAZY_STEP2.png";
import BOOKITEAZY_STEP3 from "../images/BOOKITEAZY_STEP3.png";
import BOOKITEAZY_STEP4 from "../images/BOOKITEAZY_STEP4.png";
import More_features from "../images/bookiteazy_more_features.png";
import Your_work from "../images/bookiteazy_your_work.png";
import Our_Automation from "../images/bookiteazy_automation.png";
import header_banner from "../images/bookiteazy_header_image_transparent.png";
import Footer from "../components/Footer.js";
import Header from "../components/Header.js";
import OnboardHomepageSections from "../components/OnboardHomepageSections.js";

const PaymentStatusIpe = () => {
  const { transactionTime } = useParams();
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [isFlipped, setFlipped] = useState(false);
  const [isFlipped2, setFlipped2] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    practice: "",
    plan: "Quarterly",
    number: "",
    professional: "",
  });
  const [errorFields, setErrorFields] = useState([]);
  const [redirected, setredirected] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("");
  const decodedDate = decodeURIComponent(transactionTime);

  useEffect(() => {
    const div = document.getElementById("startedSection");

    // Scroll to the destination div smoothly
    if (div) {
      div.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const navigate = useNavigate();

  const flipCard = () => {
    setFlipped(!isFlipped);
  };
  const flipCard2 = () => {
    setFlipped2(!isFlipped2);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  function checkErrors() {
    const errors = [];

    if (!formData.fullName) errors.push("Full Name");
    if (!formData.professional) errors.push("Profession");
    if (!formData.email) errors.push("Email");
    if (!formData.number) errors.push("Number");
    if (formData.number && formData.number.length !== 10)
      errors.push("Only 10 digits Contact Number allowed!");
    if (!formData.practice) errors.push("Practice Name");

    setErrorFields(errors);
    return errorFields;
  }

  const scrollTo = (id) => {
    let destinationDiv = "";
    // Find the destination div by its id
    if (id === "apply") {
      destinationDiv = document.getElementById("started");
    } else if (id === "how") {
      destinationDiv = document.getElementById("howSection");
    }

    // Scroll to the destination div smoothly
    if (destinationDiv) {
      destinationDiv.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Header />
      <div class="container">
        <OnboardHomepageSections />

        <section id="startedSection" className="mb-6">
          <div
            class="tile"
            id="started"
            style={{
              display: "block",
              backgroundColor: "#f5f5f5",
              padding: "5em",
            }}
          >
            <div
              class="control"
              style={{ fontSize: "1rem", textAlign: "center" }}
            >
              <div
                style={{
                  textAlign: "center",
                  borderRadius: "1em",
                  width: "1em",
                  padding: "1em",
                  display: "inline-block",
                  width: "fit-content",
                }}
                className="mb-4"
              >
                <svg
                  style={{
                    background: "lightsalmon",
                    margin: "0em 1em",
                    borderRadius: "1em",
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  height="40"
                  width="40"
                  viewBox="0 0 512 512"
                >
                  <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z" />
                </svg>
                <h1
                  style={{
                    fontWeight: "700",
                  }}
                  className="mb-4"
                >
                  <h1>ERROR: </h1>
                  Payment Failed!
                </h1>{" "}
                <br></br>
                <span style={{ display: "grid" }}>
                  <strong>Transaction Time: </strong> {decodedDate}
                </span>
                <br></br>
                <a
                  style={{ color: "#4a4a4a" }}
                  onClick={() => navigate("/hcp")}
                >
                  <u>Try Again!</u>
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default PaymentStatusIpe;
