import Logo from "../components/landingUI/logo.png";
import { useEffect, useState } from "react";
import "../App.css";
import { useNavigate } from "react-router-dom";

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex flex-col">
        {/* header begin */}
        <div className="flex justify-center">
          <a href="/">
            <img
              src={Logo}
              alt="MainLogo"
              className="size-56 shadow-2xl shadow-orange-600 rounded-b-2xl"
            />
          </a>
        </div>
        {/* header end */}

        <div class="mx-10 mt-20 mb-10">
          <h1 className="text-center text-orange-100 text-2xl font-semibold">
            Privacy Policy for "Bookiteazy CRYG Technologies LLP"
          </h1>
        </div>

        {/* main begin */}
        <div className="mx-10 text-orange-50">
          <p style={{ textAlign: "justify" }}>
            Effective Date: 9-10-2023
            <br></br>
            <br></br>
            Thank you for your interest in Bookiteazy CRYG Technologies LLP.
            This Privacy Policy outlines how we collect, use, and protect the
            information you provide on our page.
          </p>
          <br></br>
          <h3 style={{ textAlign: "start" }}>
            <b>Information We Collect</b>
          </h3>
          <p style={{ textAlign: "justify" }}>
            We dont not collect any information on thie
            website(https://bookiteazy.online)
          </p>

          {/* <p style={{ textAlign: "justify" }}>
            We collect the following information when you provide it to us on
            our page:
            <ul style={{ textAlign: "justify", textDecoration: "underline" }}>
              <li>Full Name</li>
              <li>Number</li>
              <li>Profession</li>
              <li>Email</li>
            </ul>
          </p> */}
          <br></br>

          <h3 style={{ textAlign: "start" }}>
            <b>How We Use Your Information</b>
          </h3>

          <p style={{ textAlign: "justify" }}>
            The information collected is used solely for the purpose of sending
            you updates and announcements related to the launch of Bookiteazy
            CRYG Technologies LLP. We do not share, sell, or distribute your
            information to third parties.
          </p>
          <br></br>
          <h3 style={{ textAlign: "start" }}>
            <b>Data Security</b>
          </h3>

          <p style={{ textAlign: "justify" }}>
            We implement appropriate security measures to protect the
            confidentiality, integrity, and availability of your information.
            However, please be aware that no method of transmission over the
            internet or electronic storage is 100% secure.
          </p>
          <br></br>
          <h3 style={{ textAlign: "start" }}>
            <b>Cookies and Analytics</b>
          </h3>
          <p style={{ textAlign: "justify" }}>
            We do not use cookies or analytics on our page.
          </p>
          <br></br>
          <h3 style={{ textAlign: "start" }}>
            <b>Your Rights</b>
          </h3>
          <p style={{ textAlign: "justify" }}>
            You have the right to access, correct, or delete your personal
            information. You can do so by contacting us at
            contact@bookiteazy.com.
          </p>
          <br></br>
          <br></br>
          <h3 style={{ textAlign: "start" }}>
            <b>Changes to this Privacy Policy</b>
          </h3>
          <p style={{ textAlign: "justify" }}>
            This Privacy Policy may be updated periodically to reflect changes
            in our practices. The updated policy will be effective immediately
            upon posting.
          </p>
        </div>
        {/* main end */}

        <footer className="mt-20 mb-5 bg-orange-400 text-white">
          <div className="content has-text-centered container">
            <p className="mt-2">
              <a
                className="text-white text-sm mx-2"
                onClick={() => navigate("/PrivacyPolicy")}
              >
                Privacy Policy
              </a>
              <span style={{ margin: "0 0.2em" }}></span>
              <a
                className="text-white text-sm mx-2"
                onClick={() => navigate("/TermsAndConditions")}
              >
                Terms and Conditions
              </a>
              <a
                className="text-white text-sm mx-2"
                onClick={() => navigate("/CancellationAndRefundPolicy")}
              >
                Cancellation/Refund Policy
              </a>
              <a
                className="text-white text-sm mx-2"
                onClick={() => navigate("/PricingPolicy")}
              >
                Pricing Policy
              </a>
              <a
                className="text-white text-sm mx-2"
                onClick={() => navigate("/ShippingPolicy")}
              >
                Shipping Policy
              </a>
              <a
                className="text-white text-sm mx-2"
                onClick={() => navigate("/ContactUs")}
              >
                Contact Us
              </a>
            </p>
            <p className="mt-2">
              © Copyright 2024 Bookiteazy CRYG Technologies LLP. <br />All rights reserved.{" "}{" "}
            </p>
            {/* <p className="text-right">India</p> */}
          </div>
        </footer>
      </div>
    </>
  );
};

export default PrivacyPolicy;
