import React from "react";
import FaqItem from "./FaqItem";

const FAQs = () => {
  const faqs = [
    {
      question: "Is your company registered?",
      answer:
        "Yes, we're a registered company in India with a valid company license and GST registration from the Government of India. Ensuring that we adhere to the regulatory standards set by the Indian government.",
    },
    {
      question: "Is your product for users(patients) live in market?",
      answer:
        "As of now, we're in the final stages of preparations and are excited to announce that our platform will go live on March 31st, 2024. Until then, we're actively onboarding healthcare professionals, offering them exclusive early bird discounts. Patients will be able to start booking appointments and enjoying our unique features once we officially launch. Stay tuned for the big day! If you have any questions or would like to know more about our platform, feel free to reach out.",
    },
    {
      question:
        "When will my subscription period begin, and how does it align with the product's go-live date for patients?",
      answer:
        "Absolutely! Your subscription period will officially commence from the date our product goes live for patients on March 31st, 2024. This means that you'll start benefiting from the features and exposure on our platform from the very moment it becomes accessible to patients. We appreciate your early commitment, and your subscription will be activated in sync with the launch to maximize your exposure and patient engagement. If you have any further questions or need assistance, feel free to let us know!",
    },
    {
      question:
        "Why should I onboard before the official launch on March 31st, 2024?",
      answer:
        "By onboarding early, you secure your spot as one of the first healthcare professionals on our platform. Plus, you'll enjoy an exclusive early bird discount on subscription fees.",
    },
    {
      question: "What is the early bird discount offer?",
      answer:
        "Our early bird offer includes a discounted subscription rate for doctors who join our platform before February 31st, 2024. It's our way of showing appreciation for early adopters.",
    },
    {
      question: "When will the early bird discount period end?",
      answer:
        "The early bird discount offer is available until February 31st, 2024. After this date, subscription prices will be adjusted to reflect the regular rates.",
    },
    {
      question: "Can I still join after February 31st, 2024?",
      answer:
        "Absolutely! You can join our platform at any time. However, the early bird discount offer will no longer be applicable after February 31st, 2024.",
    },
    {
      question:
        "What benefits do I get by joining early, considering the platform is not officially launched yet?",
      answer:
        "Early adopters enjoy several advantages, including priority positioning on our platform, exposure to our growing user base, and the exclusive early bird discount on subscription fees.",
    },
    {
      question: "When will the platform start accepting patient appointments?",
      answer:
        "Patient appointments will commence on our official launch date, March 31st, 2024. Until then, you have the opportunity to set up your profile, familiarize yourself with the platform, and benefit from the early bird offer.",
    },
    {
      question:
        "How will I be notified about appointment availability and updates?",
      answer:
        "We will keep you informed through email notifications and platform announcements. As we approach the launch date, you'll receive regular updates on upcoming features and appointment availability.",
    },
    {
      question: "What happens if I join after February 31st, 2024?",
      answer:
        "Doctors who join after Febrauary 31st, 2024 will still be welcomed on our platform. However, the early bird discount offer will no longer be applicable, and subscription prices will be at the regular rate.",
    },
    {
      question:
        "What happens if there are changes to the official launch date after I've subscribed as a doctor?",
      answer:
        "Please note that while our official launch date is currently set for March 31st, 2024, but you will get access to your subscribed plan portal login from mid-March, 2024 and circumstances beyond our control may lead to changes in the schedule. We want to keep you informed transparently. In the event of any adjustments to the launch date, subscribed doctors will be promptly notified via email and platform announcements. Your understanding is greatly appreciated as we work towards delivering the best experience for both healthcare professionals and patients.",
    },
    {
      question: "Do I need to review the privacy policy before subscribing?",
      answer:
        "Yes, we highly recommend reviewing our privacy policy before subscribing to ensure that you are aware of how we handle your personal information. Our privacy policy outlines the types of data we collect, how we use it, and the measures we take to protect your privacy. By understanding our privacy practices, you can make informed decisions about subscribing to our services. Your privacy is important to us, and we are committed to being transparent about how your information is handled. If you have any questions or concerns about our privacy policy, feel free to reach out to our support team for clarification.",
    },
  ];

  return (
    <div
      className="faqs"
      style={{
        margin: "0 1em 1em 1em",
        border: "1px solid rgb(250, 250, 250)",
        // boxShadow: "rgba(0, 0, 0, 0.09) 4px 12px 40px 6px",
        borderRadius: "2em",
      }}
    >
      <h2
        class="subtitle is-1 has-text-centered mb-6"
        style={{ marginBottom: "2rem" }}
      >
        Frequently Asked Questions (FAQs):<a>*</a>
      </h2>

      {faqs.map((faq, index) => (
        <FaqItem key={index} question={faq.question} answer={faq.answer} />
      ))}
    </div>
  );
};

export default FAQs;
