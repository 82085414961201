import Logo from "../components/landingUI/logo.png";
import { useEffect, useState } from "react";
import "../App.css";
import { useNavigate } from "react-router-dom";

const ContactUs = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex flex-col h-screen">
        {/* header begin */}
        <div className="flex justify-center">
          <a href="/">
            <img
              src={Logo}
              alt="MainLogo"
              className="size-56 shadow-2xl shadow-orange-600 rounded-b-2xl"
            />
          </a>
        </div>
        {/* header end */}

        <div class="mx-10 mt-20">
          <h1 className="text-center text-orange-100 text-2xl font-semibold">
            Contact Us for "Bookiteazy CRYG Technologies LLP"
          </h1>
          <div className="mt-20 text-center">
            <h3 className="text-orange-100 mt-10">
              <b>Contact Us via email: </b>
            </h3>
            <p>contact@bookiteazy.com</p>
            <h3 className="text-orange-100 mt-10">
              <b>Operating address: </b>
            </h3>
            <p>#16/12, 6th Cross, C.V. Raman Nagar, Bangalore 560093</p>
          </div>
        </div>
        <footer className="mt-40 bg-orange-400 text-white">
          <div className="content has-text-centered container">
            <p className="mt-2">
              <a
                className="text-white text-sm mx-2"
                onClick={() => navigate("/PrivacyPolicy")}
              >
                Privacy Policy
              </a>
              <span style={{ margin: "0 0.2em" }}></span>
              <a
                className="text-white text-sm mx-2"
                onClick={() => navigate("/TermsAndConditions")}
              >
                Terms and Conditions
              </a>
              <a
                className="text-white text-sm mx-2"
                onClick={() => navigate("/CancellationAndRefundPolicy")}
              >
                Cancellation/Refund Policy
              </a>
              <a
                className="text-white text-sm mx-2"
                onClick={() => navigate("/PricingPolicy")}
              >
                Pricing Policy
              </a>
              <a
                className="text-white text-sm mx-2"
                onClick={() => navigate("/ShippingPolicy")}
              >
                Shipping Policy
              </a>
              <a
                className="text-white text-sm mx-2"
                onClick={() => navigate("/ContactUs")}
              >
                Contact Us
              </a>
            </p>
            <p className="mt-2">
              © Copyright 2024 Bookiteazy CRYG Technologies LLP. <br />All rights reserved.{" "}{" "}
            </p>
            {/* <p className="text-right">India</p> */}
          </div>
        </footer>
      </div>
    </>
  );
};

export default ContactUs;
