import Video from "./promo_video.mp4";
import Logo from "./logo.png";
// import BookiteazyLogo from "./Bookiteazy-grocery-logo.png";
import Screenshot from "./screenshot.png";
import AppStore from "./App_Store.png";
import GooglePlay from "./Google_Play.png";
import "../../App.css";
import { useNavigate } from "react-router-dom";

const LandingUI = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex flex-col">
        <div className="flex justify-center">
          <img
            src={Logo}
            alt="MainLogo"
            className="size-56 shadow-2xl shadow-orange-600 rounded-b-2xl"
          />
        </div>

        <div className="flex flex-col  md:flex-row md:justify-around my-10">
          <div className="mx-auto md:place-content-center text-white">
            {" "}
            <h1 className="text-5xl font-extrabold p-2">SHOP LOCAL.</h1>
            <h1 className="text-5xl font-extrabold p-2">SHOP FRESH.</h1>
            <h1 className="text-6xl font-extrabold px-2 pb-2">GROCERY.</h1>
            <p className="text-lg font-extrabold px-2 pb-2 text-wrap w-72">
             New way to shop online. <br /> Completely user-friendly Interface.
              <br />{" "}
              <p className="text-sm mt-10">
                Made in India. Made for India. <br />
                Made with &nbsp;❤️&nbsp; by Bookiteazy{" "}
              </p>
            </p>
            <div className="flex flex-col bg-slate-100 p-2 rounded-2xl shadow-2xl shadow-orange-50 mt-10 w-fit mx-auto mb-10">
              <h1 className="text-slate-900 font-normal text-center pb-2">
                Available Now{" "}
              </h1>
              <div className="flex justify-center">
                <a
                  href="https://apps.apple.com/in/app/bookiteazy/id6683306143"
                  target="_blank"
                  className="mx-2"
                >
                  <img
                    src={AppStore}
                    alt="iPhone_screenshot"
                    className="w-36 h-12"
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.bookiteazycrygtechnologiesllp.Bookiteazy&pcampaignid=web_share"
                  target="_blank"
                  className="mx-2"
                >
                  <img
                    src={GooglePlay}
                    alt="iPhone_screenshot"
                    className="w-36 h-12"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="mx-auto mt-5">
            <img
              src={Screenshot}
              alt="iPhone_screenshot"
              className="w-64 rounded-b-2xl"
            />
          </div>
        </div>

        <footer className="my-10 bg-orange-400 text-white">
          <div className="content has-text-centered container">
            <p>
              {/* <p style={{ textAlign: "left" }}>
                <p style={{ textAlign: "left", lineHeight: "1.4" }}>
                  <strong>
                    <a href="https://linkedin.com/company/bookiteazy-technologies">
                      Bookiteazy Technologies
                    </a>
                  </strong>{" "}
                  by Copyright © 2023 Bookiteazy CRYG Technologies LLP. All
                  rights reserved. <p style={{ textAlign: "right" }}>India</p>
                </p>
              </p> */}
              <p className="mt-2">
                <a
                  className="text-white text-sm mx-2"
                  onClick={() => navigate("/PrivacyPolicy")}
                >
                  Privacy Policy
                </a>
                <span style={{ margin: "0 0.2em" }}></span>
                <a
                  className="text-white text-sm mx-2"
                  onClick={() => navigate("/TermsAndConditions")}
                >
                  Terms and Conditions
                </a>
                <a
                  className="text-white text-sm mx-2"
                  onClick={() => navigate("/CancellationAndRefundPolicy")}
                >
                  Cancellation/Refund Policy
                </a>
                <a
                  className="text-white text-sm mx-2"
                  onClick={() => navigate("/PricingPolicy")}
                >
                  Pricing Policy
                </a>
                <a
                  className="text-white text-sm mx-2"
                  onClick={() => navigate("/ShippingPolicy")}
                >
                  Shipping Policy
                </a>
                <a
                  className="text-white text-sm mx-2"
                  onClick={() => navigate("/ContactUs")}
                >
                  Contact Us
                </a>
              </p>
              <p className="mt-2">
                © Copyright 2024 Bookiteazy CRYG Technologies LLP. <br />
                All rights reserved.{" "}
              </p>
              {/* <p className="text-right">India</p> */}
            </p>
          </div>
        </footer>
      </div>
    </>
  );
};

export default LandingUI;
