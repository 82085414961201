export async function onBoarding(reqBody, retryCount = 3) {
  try {
    const response = await fetch(
      "https://amethyst-adder-hem.cyclic.cloud/api/clients/onboard",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody),
      }
    ).then(response => response.json()).then(res => res)

    if (!response.ok) {
      if(response.code === 404 && response.message === "Account already exist. Try Login!"){
        throw new Error("Account already exist with email or number. Try Login!");
      } else{ 
        throw new Error("Network response was not ok");
      }
    }

    // if (response.ok) {
    //   return new Promise(async (resolve) => {
    //     const jsonResponse = response.json();
    //     const data = await jsonResponse.json();
    //     resolve({ sucess: true, data });
    //   });
    // }
    return {success: true, message: response.message, code: 404}
  } catch (error) {
    return { success: false, error: error.message };
  }
}

function openUrlInNewTab(url) {

  document.getElementById('openButton').addEventListener('click', function() {
    // This should work in response to a button click
    window.open(url, '_self');
  });
}

export async function paymentIniatialize(reqBody) {
  try {
    const response = await fetch(
      "https://amethyst-adder-hem.cyclic.cloud/api/payments/pay",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody),
      }
    ).then(async response => await response.json()).then(async data => {
      const res = await data;
      return res
    })
    // openUrlInNewTab(response.url)

    // if (!response.ok) {
    //   throw new Error("Network response was not ok");
    // }
    console.log({response});
    return {success: true, data: response?.url}
    // if (response.ok) {
    //   console.log({ frontResponse: response.body });
    //   openUrlInNewTab(response.url);
    // }
    // if (response.ok === 200) {
    //  const pg = response?.location
    //  return new Promise(resolve => {
    //   localStorage.setItem("redirecting", "yes")
    //   resolve(pg)
    //  }).then(res => {
    //   console.log(res.pg);
    //  window.open(res.pg, "_blank")
    //  })
    // }
  } catch (error) {
    return { success: false, error: error.message };
  }
}

export async function paymentStatusCheck(trasactionid) {
  try {
    const response = await fetch(
      `https://amethyst-adder-hem.cyclic.cloud/api/payments/status/${trasactionid}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json", 
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        // Log the response only when the entire Promise chain has completed
        return data;
      });

    return response
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
  } catch (error) {
    return { success: false, error: error.message };
  }
}

export async function callCheckStatusAPI(paymentStatus) {
  let interval;
  let elapsedTime = 0;

  // Define the intervals based on the payment status
  const intervals = [
    { delay: 20000, duration: 5000 }, // First status check at 20-25 seconds
    { delay: 3000, duration: 30000 }, // Every 3 seconds for the next 30 seconds
    { delay: 6000, duration: 60000 }, // Every 6 seconds for the next 60 seconds
    { delay: 10000, duration: 60000 }, // Every 10 seconds for the next 60 seconds
    { delay: 30000, duration: 60000 }, // Every 30 seconds for the next 60 seconds
    { delay: 60000, duration: 1200000 }, // Every 1 min until timeout (20 mins)
  ];

  // Function to simulate API call
  function paymentStatusCheck() {
    // Replace the following line with your actual API call
    // fetch('your-check-status-api-endpoint')
    //   .then(response => response.json())
    //   .then(data => {
    //     // Handle the API response based on the payment status
    //     if (data.paymentStatus === 'Pending') {
    //       // Handle the Pending status
    //     } else {
    //       // Handle other statuses
    //     }
    //   })
    //   .catch(error => console.error('Error:', error));

    // Simulate API response based on paymentStatus
    if (paymentStatus === "Pending") {
      // Simulate pending status (replace with actual handling)
      console.log("Payment status is Pending. Continue checking...");
    } else {
      // Simulate other statuses (replace with actual handling)
      console.log("Payment status is not Pending. Stop checking.");
      clearInterval(interval);
    }
  }

  // Function to handle intervals
  function handleIntervals() {
    for (const { delay, duration } of intervals) {
      setTimeout(() => {
        paymentStatusCheck();
      }, elapsedTime + delay);
      elapsedTime += delay + duration;
    }
  }

  // Start checking status immediately
  paymentStatusCheck();

  // Start intervals
  handleIntervals();

  // Set a timeout to stop checking after 20 mins (adjust as needed)
  setTimeout(() => {
    clearInterval(interval);
    console.log("Timeout. Stop checking.");
  }, 1200000); // 20 mins
}
