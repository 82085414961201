import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  onBoarding,
  paymentIniatialize,
  paymentStatusCheck,
} from "../API/index";
import Logo from "../images/logo.png";
import BOOKITEAZY_STEP1 from "../images/BOOKITEAZY_STEP1.png";
import BOOKITEAZY_STEP2 from "../images/BOOKITEAZY_STEP2.png";
import BOOKITEAZY_STEP3 from "../images/BOOKITEAZY_STEP3.png";
import BOOKITEAZY_STEP4 from "../images/BOOKITEAZY_STEP4.png";
import More_features from "../images/bookiteazy_more_features.png";
import Your_work from "../images/bookiteazy_your_work.png";
import Our_Automation from "../images/bookiteazy_automation.png";
import header_banner from "../images/bookiteazy_header_image_transparent.png";
import Footer from "../components/Footer.js";
import Header from "../components/Header.js";
import OnboardHomepageSections from "../components/OnboardHomepageSections.js";

const PaymentStatus = () => {
  const { status, transactionID, transactionTime } = useParams();
  const [currentPaymentState, setCurrentPaymentState] = useState("");
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [isFlipped, setFlipped] = useState(false);
  const [isFlipped2, setFlipped2] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    practice: "",
    plan: "Quarterly",
    number: "",
    professional: "",
  });
  const [errorFields, setErrorFields] = useState([]);
  const [redirected, setredirected] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const div = document.getElementById("startedSection");

    // Scroll to the destination div smoothly
    if (div) {
      div.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    if (status.charAt(0) === "U" || "C" || "N") {
      setCurrentPaymentState("success");
    } else if (status === "pending") {
      setCurrentPaymentState("pending");
    }
  }, [status]);

  const flipCard = () => {
    setFlipped(!isFlipped);
  };
  const flipCard2 = () => {
    setFlipped2(!isFlipped2);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  function checkErrors() {
    const errors = [];

    if (!formData.fullName) errors.push("Full Name");
    if (!formData.professional) errors.push("Profession");
    if (!formData.email) errors.push("Email");
    if (!formData.number) errors.push("Number");
    if (formData.number && formData.number.length !== 10)
      errors.push("Only 10 digits Contact Number allowed!");
    if (!formData.practice) errors.push("Practice Name");

    setErrorFields(errors);
    return errorFields;
  }

  const handleSubmit = () => {
    setButtonDisabled(true);
    const payload = {
      fullName: formData.fullName,
      email: formData.email,
      professional: formData.professional,
      practiceName: formData.practice,
      plan: formData.plan,
      number: formData.number,
    };
    // Check all steps for incomplete fields
    const err = checkErrors();

    if (
      formData.fullName &&
      formData.email &&
      formData.number &&
      formData.plan &&
      formData.practice &&
      formData.professional &&
      formData.number.length === 10
    ) {
      onBoarding(payload)
        .then(async (onBoardingRespone) => {
          const reqBody = {
            plan: formData.plan,
            fullName: formData.fullName,
            client_id: onBoardingRespone?.data,
          };
          setredirected(true);
          const res = await paymentIniatialize(reqBody);
          setTimeout(() => {
            window.location.href = res.data;
          }, 2000);
        })
        .catch((err) => {
          console.log("Error in Submit API", err);
        });
    }
  };

  const scrollTo = (id) => {
    let destinationDiv = "";
    // Find the destination div by its id
    if (id === "apply") {
      destinationDiv = document.getElementById("started");
    } else if (id === "how") {
      destinationDiv = document.getElementById("howSection");
    }

    // Scroll to the destination div smoothly
    if (destinationDiv) {
      destinationDiv.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Header />
      <div class="container">
        <OnboardHomepageSections />
        {currentPaymentState === "success" && (
          <section id="startedSection" className="mb-6">
            <div
              class="tile"
              id="started"
              style={{
                display: "block",
                backgroundColor: "#f5f5f5",
                padding: "5em",
              }}
            >
              <div
                class="control"
                style={{ fontSize: "1rem", textAlign: "center" }}
              >
                <div
                  style={{
                    textAlign: "left",
                    borderRadius: "1em",
                    width: "1em",
                    padding: "1em",
                    display: "inline-block",
                    width: "fit-content",
                  }}
                  className="mb-4"
                >
                  <svg
                    style={{
                      background: "palegreen",
                      margin: "0em 1em",
                      borderRadius: "1em",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    height="40"
                    width="40"
                    viewBox="0 0 512 512"
                  >
                    <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                  </svg>
                  <h1
                    style={{
                      color: "green",
                      fontWeight: "700",
                    }}
                    className="mb-4"
                  >
                    SUCCESS
                  </h1>
                  <span style={{ display: "grid" }}>
                    <strong>Transaction ID: </strong> {transactionID}
                  </span>
                  <br></br>
                  <span style={{ display: "grid" }}>
                    {status && status.charAt(0) === "U" && (
                      <>
                        <strong>UTR: </strong>
                        {status.slice(1)}
                      </>
                    )}
                    {status && status.charAt(0) === "C" && (
                      <>
                        <strong>ARN: </strong>
                        {status.slice(1)}
                      </>
                    )}
                    {status && status.charAt(0) === "N" && (
                      <strong>Bank Transaction ID: {status.slice(1)}</strong>
                    )}
                  </span>
                  <br></br>
                  <span style={{ display: "grid" }}>
                    <strong>PAID AT: </strong> {transactionTime}
                  </span>
                </div>
                <br></br>
                Thank you for being an early adopter of Bookiteazy.<br></br>{" "}
                We're working diligently to ensure a smooth onboarding
                experience and can't wait for you to explore the platform.
                <br></br>
                <br></br>
                <strong>
                  Please check your inbox or spam/junk folder for the invoice
                  related to your recent subscription purchase with Bookiteazy.
                </strong>
                <br></br>
                Need help? contact@bookiteazy.com
              </div>
            </div>
          </section>
        )}

        {currentPaymentState === "failed" && (
          <section id="startedSection" className="mb-6">
            <div
              class="tile"
              id="started"
              style={{
                display: "block",
                backgroundColor: "#f5f5f5",
                padding: "5em",
              }}
            >
              <div
                class="control"
                style={{ fontSize: "1rem", textAlign: "center" }}
              >
                <div
                  style={{
                    textAlign: "center",
                    borderRadius: "1em",
                    width: "1em",
                    padding: "1em",
                    display: "inline-block",
                    width: "fit-content",
                  }}
                  className="mb-4"
                >
                  <svg
                    style={{
                      background: "lightsalmon",
                      margin: "0em 1em",
                      borderRadius: "1em",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    height="40"
                    width="40"
                    viewBox="0 0 512 512"
                  >
                    <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z" />
                  </svg>

                  <h1
                    style={{
                      color: "orangered",
                      fontWeight: "700",
                    }}
                    className="mb-4"
                  >
                    FAILED
                  </h1>
                  <span style={{ display: "grid" }}>
                    <strong>Transaction ID: </strong> {transactionID}
                  </span>
                  <br></br>
                  <h2
                    class="subtitle is-1 has-text-centered mb-4"
                    style={{ marginBottom: "2rem" }}
                  >
                    See your practice grow with the help of Bookiteazy.<a>*</a>
                  </h2>
                  <br></br>
                  <strong
                    style={{
                      border: "1px solid black",
                      padding: "1em",
                      borderRadius: "0 2em",
                    }}
                  >
                    RETRY PAYMENT
                  </strong>
                </div>
                <div class="field is-grouped is-grouped-multiline is-grouped-centered">
                  <div class="field mr-2">
                    <label class="label">Full Name</label>
                    <div class="control">
                      <input
                        onChange={handleInputChange}
                        class="input"
                        name="fullName"
                        type="text"
                        placeholder="Chintan Raval"
                      />
                    </div>
                  </div>
                  <div class="field mr-2 ">
                    <label class="label">Profession</label>
                    <div class="select is-normal" style={{ width: "13em" }}>
                      <select
                        name="professional"
                        style={{ width: "13em" }}
                        onChange={handleInputChange}
                      >
                        <option value={""} disabled selected>
                          --Select--
                        </option>
                        <option value={"Doctor"}>Doctor</option>
                        <option value={"Dentist"}>Dentist</option>
                        <option value={"Therapist"}>Therapist</option>
                      </select>
                    </div>
                  </div>{" "}
                  <div class="field mr-2 ">
                    <label class="label">Email</label>
                    <div class="control">
                      <input
                        onChange={handleInputChange}
                        class="input"
                        type="email"
                        name="email"
                        placeholder="Email id"
                      />
                    </div>
                  </div>
                  <div class="field mr-2 ">
                    <label class="label">Number</label>
                    <div class="control">
                      <input
                        onChange={handleInputChange}
                        class="input"
                        type="text"
                        name="number"
                        placeholder="1234567890"
                      />
                    </div>
                  </div>
                  <div class="field">
                    <label class="label">Practice Name</label>
                    <div class="control">
                      <input
                        onChange={handleInputChange}
                        class="input"
                        type="email"
                        name="practice"
                        placeholder="Practice name"
                      />
                    </div>
                  </div>
                  <div class="field control has-text-centered">
                    <label class="label">
                      Which Plan works for you?<br></br>
                      <small>(most preferred quarterly)</small>
                    </label>
                    <label class="radio mr-2 plans">
                      <input
                        onChange={handleInputChange}
                        type="radio"
                        className="mr-1"
                        name="plan"
                        value={"Quarterly"}
                        checked
                      />
                      <b>Quaterly</b>
                      <br></br>
                      <i>
                        <s>Rs. 4500</s>
                        <br></br>
                        Get 20%
                      </i>
                      <br></br>
                      Rs. 3600
                    </label>
                    <label class="radio mr-2  plans">
                      <input
                        onChange={handleInputChange}
                        type="radio"
                        className="mr-1"
                        value={"SemiAnnual"}
                        name="plan"
                      />
                      <b>Semi Annual</b>
                      <br></br>{" "}
                      <i>
                        <s>Rs. 9000</s>
                        <br></br>
                        Get 25%
                      </i>
                      <br></br>
                      Rs. 6750
                    </label>
                    <label class="radio plans mb-4">
                      <input
                        onChange={handleInputChange}
                        type="radio"
                        className="mr-1"
                        value={"Annual"}
                        name="plan"
                      />
                      <b>Annual</b>
                      <br></br>
                      <i>
                        <s>Rs. 18000</s>
                        <br></br>
                        Get 30%
                      </i>
                      <br></br>
                      Rs. 12600
                    </label>
                    {errorFields.length > 0 && (
                      <p
                        style={{
                          width: "23em",
                          color: "red",
                          fontWeight: "400",
                        }}
                        className="mb-2"
                      >
                        # Please fill out fields:
                        <b> {errorFields.join(", ")}</b>
                      </p>
                    )}
                    <p class="control">
                      <a
                        class="button is-primary"
                        disabled={isButtonDisabled}
                        onClick={() => handleSubmit()}
                      >
                        {isButtonDisabled ? "Submitting..." : "Submit"}
                      </a>
                    </p>
                  </div>
                </div>
                <br></br>
                Need help? contact@bookiteazy.com
              </div>
            </div>
          </section>
        )}

        {currentPaymentState === "pending" && (
          <section id="startedSection" className="mb-6">
            <div
              class="tile"
              id="started"
              style={{
                display: "block",
                backgroundColor: "#f5f5f5",
                padding: "5em",
              }}
            >
              <div
                class="control"
                style={{ fontSize: "1rem", textAlign: "center" }}
              >
                <div
                  style={{
                    textAlign: "center",
                    borderRadius: "1em",
                    width: "1em",
                    padding: "1em",
                    display: "inline-block",
                    width: "fit-content",
                  }}
                  className="mb-4"
                >
                  <svg
                    style={{
                      background: "yellow",
                      margin: "0em 1em",
                      borderRadius: "1em",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    height="40"
                    width="40"
                    viewBox="0 0 512 512"
                  >
                    <path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
                  </svg>

                  <h1
                    style={{
                      color: "dimgray",
                      fontWeight: "700",
                    }}
                    className="mb-4"
                  >
                    PENDING
                  </h1>
                  <span style={{ display: "grid" }}>
                    <strong>Transaction ID: </strong> {transactionID}
                  </span>
                  <br></br>
                  <span style={{ display: "grid" }}>
                    {status && status.charAt(0) === "U" && (
                      <>
                        <strong>UTR: </strong>
                        {status}
                      </>
                    )}
                    {status && status.charAt(0) === "C" && (
                      <>
                        <strong>ARN: </strong>
                        {status}
                      </>
                    )}
                    {status && status.charAt(0) === "N" && (
                      <strong>Bank Transaction ID: {status}</strong>
                    )}
                  </span>
                  <br></br>
                  <span style={{ display: "grid" }}>
                    <strong>PAID AT: </strong> 125Y924869346
                  </span>
                </div>
                <br></br>
                Thank you for being an early adopter of Bookiteazy.<br></br>{" "}
                We're working diligently to ensure a smooth onboarding
                experience and can't wait for you to explore the platform.
                <br></br>
                <br></br>
                <strong>
                  Please check your inbox or spam/junk folder for the invoice
                  related to your recent subscription purchase with Bookiteazy.
                </strong>
                <br></br>
                Need help? contact@bookiteazy.com
              </div>
            </div>
          </section>
        )}
      </div>
      <Footer />
    </>
  );
};

export default PaymentStatus;
